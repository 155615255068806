// eslint-disable-next-line no-unused-vars
import type { NextPage } from 'next'
import AuthLayout from '@@/AuthLayout'
import Login from '../containers/Login'
import useNoRequiredAuth from '~/hooks/useNoRequiredAuth'

const LoginPage: NextPage = () =>
  useNoRequiredAuth(() => (
    <AuthLayout>
      <Login />
    </AuthLayout>
  ))

export default LoginPage
