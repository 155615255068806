import { Form } from 'antd'
import Password from '@@/Password'
import Button from '@@/Button'
import Centered from '@@/Centered'
import FloatingLabelInput from '@@/FloatingLabelInput'
import { useState } from 'react'
import Link from '@@/Link'

const LoginForm = ({ form, onFinish, loading }: any) => {
  const [email, setEmail] = useState<null | string>(null)
  const forgotPasswordLink = email
    ? `/forgot-password?email=${email}`
    : '/forgot-password'

  return (
    <Form
      layout="vertical"
      className="space-y-6"
      form={form}
      onFinish={onFinish}
    >
      <Form.Item
        name="email"
        rules={[
          {
            type: 'email',
            required: true,
            message: 'Please enter a valid email',
          },
        ]}
      >
        <FloatingLabelInput
          label="Email"
          data-testid="email"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Please enter the password',
          },
        ]}
      >
        <Password
          label="Password"
          data-testid="password"
        />
      </Form.Item>
      <div className="flex items-center justify-between">
        <div className="flex items-center -ml-6">
          <input
            id="remember-me"
            name="remember-me"
            type="checkbox"
            className="h-4 w-4 text-indigo-600 border-gray-300 bg-gray-50 rounded"
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label
            htmlFor="remember-me"
            className="ml-2 block !text-base text-text-color"
          >
            Remember me
          </label>
        </div>

        <div>
          <Link
            data-testid="forgot-password"
            className="text-base"
            href={forgotPasswordLink}
          >
            Forgot your password?
          </Link>
        </div>
      </div>
      <Centered>
        <Button
          data-testid="login"
          loading={loading}
          type="submit"
        >
          Sign in
        </Button>
      </Centered>
    </Form>
  )
}

export default LoginForm
