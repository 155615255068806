import { useLoginForm } from '@coding4tomorrow/c4t-next-core'
import { Form } from 'antd'
import LoginForm from '@/LoginForm'
import H2 from '@@/H2'
import P from '@@/P'
import Button from '@@/Button'
import Image from 'next/image'
import Link from '@@/Link'

const Login = () => {
  const [form] = Form.useForm()
  const { ErrorComponent, loading, onFinish } = useLoginForm({
    form,
    loginRoute: 'auth.login',
    redirectTo: '/',
  })

  return (
    <div className="min-h-full flex flex-col justify-center py-12 px-14">
      <H2 className="mb-6">Sign in to your account</H2>
      <P className="mb-3 !text-left">Sign in with</P>
      <div className="w-full grid grid-cols-3 gap-2">
        <Button
          buttonType="icon"
          className="w-full"
        >
          <Image
            src="/images/facebook-logo.svg"
            alt="facebook"
            width={26}
            height={26}
            layout="fixed"
          />
        </Button>
        <Button
          buttonType="icon"
          className="w-full"
        >
          <Image
            src="/images/google-logo.svg"
            alt="google"
            width={26}
            height={26}
            layout="fixed"
          />
        </Button>
        <Button
          buttonType="icon"
          className="w-full"
        >
          <Image
            src="/images/apple-logo.svg"
            alt="apple"
            width={26}
            height={26}
            layout="fixed"
          />
        </Button>
      </div>
      <div className="relative flex py-6 items-center">
        <div className="flex-grow border-t border-gray-900" />
        <P className="flex-shrink mx-8 text-center">Or continue with</P>
        <div className="flex-grow border-t border-gray-900" />
      </div>
      <ErrorComponent />
      <LoginForm
        form={form}
        onFinish={onFinish}
        loading={loading}
      />
      <P className="mt-4 text-center font-normal">
        You don&apos;t have an account yet,{' '}
        <Link
          href="/register"
          className="text-link-color"
        >
          create your account here
        </Link>
      </P>
    </div>
  )
}

export default Login
