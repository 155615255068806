/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import FloatingLabelInput from '@@/FloatingLabelInput'
import { useState, useCallback, useMemo } from 'react'
import { EyeSlashIcon, EyeIcon as EyeOnIcon } from '@heroicons/react/24/solid'

const Password = (props: any) => {
  const [showPassword, setShowPassword] = useState(false)
  const EyeIcon = showPassword ? EyeOnIcon : EyeSlashIcon
  const handelShowPassword = useCallback(() => {
    setShowPassword((currentValue) => !currentValue)
  }, [])
  const PasswordIcon = useMemo(
    () =>
      // eslint-disable-next-line react/no-unstable-nested-components
      function Icon() {
        return (
          <span onClick={handelShowPassword}>
            <EyeIcon className="w-4 h-4" />
          </span>
        )
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [EyeIcon, handelShowPassword],
  )

  return (
    <FloatingLabelInput
      type={showPassword ? 'text' : 'password'}
      label="Password"
      icon={<PasswordIcon />}
      {...props}
    />
  )
}

export default Password
