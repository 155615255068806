import { useRouter } from 'next/router'
import { useUser } from '@coding4tomorrow/c4t-next-core'

const useNoRequiredAuth = (Component: () => any, redirectTo: string = '/') => {
  const router = useRouter()
  const { user, isLoading, loggedOut } = useUser()

  if (loggedOut) {
    return Component()
  }

  if (typeof window !== 'undefined' && user && !isLoading) {
    router?.push(redirectTo)
  }
  return null
}
export default useNoRequiredAuth
